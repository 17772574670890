import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import React from "react";
import PublicIcon from "@material-ui/icons/Public";
import { emUrl } from "./util";

const ExternalLinkContext = React.createContext(
  (url: string, eid: number) => {}
);

export function useOpenExternalLink() {
  return React.useContext(ExternalLinkContext);
}

export function ExternalLinkProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [state, setState] = React.useState<null | { url: string; eid: number }>(
    null
  );

  const handleClose = () => {
    setState(null);
  };

  return (
    <ExternalLinkContext.Provider
      value={(url: string, eid: number) => {
        setState({ url, eid });
      }}
    >
      {children}
      <Dialog
        open={!!state}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Externe Anmeldung"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Die Anmeldung zu dieser Veranstaltung wird nicht direkt mit dem
            Anmeldeportal der KSL verwaltet. Die Anmeldung erfolgt über eine
            externe Website, indem Sie dem Link unten folgen. Das
            Anmeldeportal-Team ist weder verantwortlich für den Inhalt der
            externen Webseite, noch kann es Ihre Anmeldung einsehen oder
            verwalten.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            href={state?.url}
            color="primary"
            startIcon={<PublicIcon />}
            onClick={(e) => {
              e.preventDefault();
              window.location.href = emUrl(
                "user",
                "redirectToExternalRegistration",
                { eid: state?.eid }
              );
            }}
          >
            Link zur externen Anmeldung
          </Button>
        </DialogActions>
      </Dialog>
    </ExternalLinkContext.Provider>
  );
}
