import HomeIcon from "@material-ui/icons/Home";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import SchoolIcon from "@material-ui/icons/School";
import { useRouter } from "next/router";
import { emUrl } from "./util";
import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  createStyles,
  Hidden,
  makeStyles,
  Theme,
  useMediaQuery,
  withStyles,
} from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { useUser } from "./user";
import useSWR from "swr";
import { RegistrationEntity } from "./entities";
import { ELTERNSPRECHTAG_CATEGORY_ID } from "./shared/constants";

// This could be expanded in the future to be a more generalised LayoutProvider, if needed.
export const BottomNavigationContext = React.createContext<{
  visible: boolean;
}>({ visible: false });

export function BottomNavigationProvider(props: React.PropsWithChildren<{}>) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const visible = isMobile;

  return (
    <BottomNavigationContext.Provider
      value={{
        visible,
      }}
    >
      {props.children}
      {visible && <MyBottomNavigation />}
    </BottomNavigationContext.Provider>
  );
}

export function useBottomNavigation() {
  const { visible } = useContext(BottomNavigationContext);
  return visible;
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: theme.zIndex.appBar,
    boxShadow: theme.shadows[8],
  },
}));

export const BOTTOM_NAV_HEIGHT = 56;

const RightAlignedBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: -4,
      top: "50%",
      border: `2px solid ${theme.palette.secondary.main}`,
      padding: "0 4px",
    },
  })
)(Badge);

export function MyBottomNavigation() {
  const classes = useStyles();
  const router = useRouter();
  const user = useUser();

  const shouldFetchRegistrations =
    user.loggedIn || user.authorizedEmailAddresses.length > 0;

  const { data } = useSWR<{
    registrations: RegistrationEntity[];
  }>(shouldFetchRegistrations ? emUrl("rest", "recentRegistrations") : null, {
    refreshInterval: 20000,
  });

  const [selectedEntry, setSelectedEntry] = useState("---");

  useEffect(() => {
    if (router.pathname === "/") {
      setSelectedEntry("startPage");
    } else if (router.asPath === `/category/${ELTERNSPRECHTAG_CATEGORY_ID}`) {
      setSelectedEntry("elternsprechtag");
    } else if (router.asPath === `/registrations`) {
      setSelectedEntry("registrations");
    } else {
      setSelectedEntry("---");
    }
  }, [router.pathname, router.asPath]);

  return (
    <BottomNavigation showLabels className={classes.root} value={selectedEntry}>
      <BottomNavigationAction
        value="startPage"
        onClick={() => router.push("/").then(() => window.scrollTo(0, 0))}
        label="Startseite"
        icon={<HomeIcon />}
      />
      {/*
        <BottomNavigationAction
          value="elternsprechtag"
          onClick={() =>
            router
              .push(`/category/${ELTERNSPRECHTAG_CATEGORY_ID}`)
              .then(() => window.scrollTo(0, 0))
          }
          label="Elternsprechtag"
          icon={<SchoolIcon />}
        />
      */}
      <BottomNavigationAction
        value="registrations"
        label={
          <>
            <Hidden xsDown>Meine</Hidden> Anmeldungen
          </>
        }
        icon={
          <RightAlignedBadge
            badgeContent={data?.registrations?.length ?? "?"}
            color="primary"
          >
            <SupervisorAccountIcon />
          </RightAlignedBadge>
        }
        onClick={() =>
          router.push(`/registrations`).then(() => window.scrollTo(0, 0))
        }
      />
    </BottomNavigation>
  );
}
