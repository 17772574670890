import { init } from "@socialgouv/matomo-next";
import isDnt from "./is-dnt";
import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

const MATOMO_URL = process.env.NEXT_PUBLIC_MATOMO_URL;
const MATOMO_SITE_ID = process.env.NEXT_PUBLIC_MATOMO_SITE_ID;

export function MatomoTracker() {
  // const {
  //   consent: { acceptedVersion },
  // } = useCookieConsentCategory("matomo");
  const initialized = useRef(false);

  if (MATOMO_URL && MATOMO_SITE_ID && process.env.NODE_ENV === "production") {
    // It is okay to run this effect inside an if statement, since all parameters are constant at runtime.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(
      () => {
        if (
          // acceptedVersion === PRIVACY_CURRENT_VERSION &&
          !initialized.current &&
          isDnt() !== true // no point in loading the tracker script when the user does not allow to be tracked
        ) {
          init({ url: MATOMO_URL, siteId: MATOMO_SITE_ID });
          initialized.current = true; // avoid double initialization
        }
      },
      [
        /*acceptedVersion*/
      ]
    );
  }

  return null;
}

export function MatomoOptOutDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="simple-dialog-title">Matomo Tracking</DialogTitle>
      <DialogContent>
        <MatomoIframe />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(() => ({
  iframe: {
    border: 0,
    width: "100%",
    height: 300,
  },
}));

export function MatomoIframe() {
  const classes = useStyles();
  return (
    <iframe
      className={classes.iframe}
      src={`${process.env.NEXT_PUBLIC_MATOMO_URL}/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=343434&fontSize=14px&fontFamily=%22Roboto%22%2C%22Helvetica%20Neue%22%2CHelvetica%2CArial%2Csans-serif`}
    />
  );

  /*
  const [status, setStatus] = useState<"loading" | "enabled" | "disabled">(
    "loading"
  );

  useEffect(() => {
    betterFetcher(
      `${MATOMO_URL}/index.php?module=API&method=AjaxOptOut.isTracked&format=json`
    ).then((response) => console.log(response));
  }, []);

  return (
    <>
      <Typography variant="body2">
        Sie haben die Möglichkeit zu verhindern, dass von Ihnen hier getätigte
        Aktionen durch Matomo analysiert und verknüpft werden. Dies wird Ihre
        Privatsphäre schützen, aber hindert uns gleichzeitig daran, aus Ihren
        Aktionen zu lernen und die Bedienbarkeit für Sie und andere Benutzer zu
        verbessern.
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            size="small"
            checked={status === "enabled"}
            indeterminate={status === "loading"}
            disabled={status === "loading"}
            onChange={() => {
              // TODO
            }}
          />
        }
        label={
          status === "loading"
            ? "Wird geladen..."
            : status === "disabled"
            ? "Ihr Besuch dieser Webseite wird aktuell von der Matomo Webanalyse nicht erfasst. Diese Checkbox aktivieren für Opt-In."
            : status === "enabled"
            ? "Ihr Besuch dieser Webseite wird aktuell von der Matomo Webanalyse erfasst. Diese Checkbox abwählen für Opt-Out."
            : null
        }
      />
    </>
  );
  */
}
