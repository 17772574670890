import { useState, useEffect } from "react";
import { ColorEntity } from "./entities";

export function emUrl(
  type: string,
  func: string,
  params: Record<string, string | number | null | undefined> = {},
  isServerSide: boolean = false
): string {
  return modUrl("EventManager", type, func, params, isServerSide);
}

export function modUrl(
  module: string,
  type: string,
  func: string,
  params: Record<string, string | number | null | undefined> = {},
  isServerSide: boolean = false
): string {
  let url = isServerSide
    ? process.env.SERVER_EM_AP_URL
    : process.env.NEXT_PUBLIC_EM_AP_URL;
  if (module === "EventManager" && type === "user" && func === "main") {
    if (Object.keys(params).length > 0) {
      url += "/?";
    }
  } else {
    url += `/index.php?module=${encodeURIComponent(
      module
    )}&type=${encodeURIComponent(type)}&func=${encodeURIComponent(func)}`;
  }

  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined && value !== null) {
      url += `&${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }
  }
  return url;
}

export function colorToHex(color: null): null;
export function colorToHex(color: ColorEntity): string;
export function colorToHex(color: ColorEntity | null): string | null;
export function colorToHex(color: ColorEntity | null): string | null {
  if (!color) {
    return null;
  }

  function toHex(n: number) {
    let r = n.toString(16);
    if (r.length === 1) {
      r = `0${r}`;
    }
    return r;
  }

  return `#${toHex(color.r)}${toHex(color.g)}${toHex(color.b)}`;
}

export function colorToRgba(color: null, alpha: number): null;
export function colorToRgba(color: ColorEntity, alpha: number): string;
export function colorToRgba(
  color: ColorEntity | null,
  alpha: number
): string | null;
export function colorToRgba(
  color: ColorEntity | null,
  alpha: number = 1
): string | null {
  if (!color) {
    return null;
  }

  return `rgba(${color.r},${color.g},${color.b},${alpha})`;
}

export function hexToColor(color: string): ColorEntity | null {
  if (color === "") {
    return null;
  }

  return {
    r: parseInt(color.substr(1, 2), 16),
    g: parseInt(color.substr(3, 2), 16),
    b: parseInt(color.substr(5, 2), 16),
  };
}

export function rawUrl(pathAndQuery: string) {
  return process.env.NEXT_PUBLIC_EM_AP_URL + pathAndQuery;
}

export const SPACING = 2;

// TODO: Deprecate this, always use swrFetcher (=> errors on non-200)
export const fetcher = (
  input: RequestInfo,
  init?: RequestInit,
  jsonResponse: boolean = true
) => {
  return betterFetcher(input, init).then((res) =>
    jsonResponse ? res.json() : res.text()
  );
};

export class Not200Error extends Error {
  constructor(public info: string, public status: number) {
    super();
  }
}

export const swrFetcher = async (
  input: RequestInfo,
  init?: RequestInit,
  jsonResponse: boolean = true
) => {
  const res = await betterFetcher(input, init);
  const content = await (jsonResponse ? res.json() : res.text());
  if (!res.ok) {
    throw new Not200Error(content, res.status);
  }
  return content;
};

export const betterFetcher = (input: RequestInfo, init?: RequestInit) => {
  return fetch(input, {
    credentials: "include",
    ...init,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-By": "EM",
      ...init?.headers,
    },
  });
};

export function times<R, F extends (i: number) => R>(n: number, fn: F) {
  return Array.from({ length: n }, (_, i) => fn(i));
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<
    { width: number; height: number } | { width: undefined; height: undefined }
  >({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}
