import React from "react";
import useSWR from "swr";
import { emUrl } from "../src/util";

const defaultData = {
  user: {
    isReady: false,

    loggedIn: false,
    uid: null,
    username: null,
    email: null,
    alreadySubscribedToNewsletter: false,
    permissions: {
      TEACHER: false,
      SUPERTEACHER: false,
      SUPERADMIN: false,
    },

    // Session info
    csrf: "",
    // authorizedEventIds: [],
    authorizedEmailAddresses: [],
  },
};

export const GlobalContext = React.createContext<{
  user: {
    isReady: boolean;

    loggedIn: boolean;
    uid: number | null;
    username: string | null;
    email: string | null;
    alreadySubscribedToNewsletter: boolean;
    permissions: {
      TEACHER: boolean;
      SUPERTEACHER: boolean;
      SUPERADMIN: boolean;
    };

    // Session info
    csrf: string;
    // authorizedEventIds: number[];
    authorizedEmailAddresses: string[];
  };
}>(defaultData);

export function useUser() {
  return React.useContext(GlobalContext).user;
}

export function useCSRF() {
  return useUser().csrf;
}

export function GlobalDataProvider(props: React.PropsWithChildren<{}>) {
  const [value, setValue] = React.useState(defaultData);

  const { data, error } = useSWR(emUrl("rest", "status"));
  React.useEffect(() => {
    if (!error && data) {
      setValue(data);
      // } else {
      //   setValue(defaultData);
    }
  }, [data, error]);

  return (
    <GlobalContext.Provider value={value}>
      {props.children}
    </GlobalContext.Provider>
  );
}
