import { Button } from "@material-ui/core";
import { addMonths } from "date-fns";
import { useCookies, Cookies } from "react-cookie";
import { makeStyles } from "@material-ui/core/styles";

const META_COOKIE_NAME = "meta";

export type CookieConsent = {
  version: number;
  categories: {
    privacyPolicy: CookieConsentCategory;
    // matomo: CookieConsentCategory;
    osmMap: CookieConsentCategory;
  };
};

export type CookieConsentCategory = {
  acceptedAt: Date | null;
  acceptedVersion: number;
};

export type CookieConsentCategoryName = keyof CookieConsent["categories"];

function sanitizeConsentCategory(oldContent: any) {
  const version = oldContent?.acceptedVersion;
  if (
    typeof version === "number" &&
    Number.isInteger(version) &&
    version >= 0
  ) {
    const date = new Date(oldContent?.acceptedAt);
    return {
      acceptedAt: isNaN(date as any) ? null : date,
      acceptedVersion: version,
    };
  } else {
    return {
      acceptedAt: null,
      acceptedVersion: -1,
    };
  }
}

function sanitizeConsent(oldConsent: any): CookieConsent {
  switch (oldConsent?.version) {
    case 1:
      return {
        version: 2,
        categories: {
          privacyPolicy: {
            acceptedAt: null,
            acceptedVersion: oldConsent?.bannerHidden === true ? 1 : -1,
          },
          // matomo: {
          //   acceptedAt: null,
          //   acceptedVersion: -1,
          // },
          osmMap: {
            acceptedAt: null,
            acceptedVersion: -1,
          },
        },
      };
    case 2:
      return {
        version: 2,
        categories: {
          privacyPolicy: sanitizeConsentCategory(
            oldConsent?.categories?.privacyPolicy
          ),
          // matomo: sanitizeConsentCategory(oldConsent?.categories?.matomo),
          osmMap: sanitizeConsentCategory(oldConsent?.categories?.osmMap),
        },
      };
    default:
      return {
        version: 2,
        categories: {
          privacyPolicy: {
            acceptedAt: null,
            acceptedVersion: -1,
          },
          // matomo: {
          //   acceptedAt: null,
          //   acceptedVersion: -1,
          // },
          osmMap: {
            acceptedAt: null,
            acceptedVersion: -1,
          },
        },
      };
  }
}

export function useCookieConsent(): {
  consent: CookieConsent;
  accept: (category: CookieConsentCategoryName, version: number) => void;
  reject: (category: CookieConsentCategoryName) => void;
} {
  const [cookies, setCookie] = useCookies([META_COOKIE_NAME]);
  const consent = sanitizeConsent(cookies[META_COOKIE_NAME] ?? {});

  const updateConsent = (
    category: CookieConsentCategoryName,
    version: number
  ) => {
    // Important: Use current cookie value when updating.
    // Even though this hook causes a re-render on cookie changes, the re-render might not yet have occurred
    // when changing the cookie multiple times inside the same event handler.
    const oldConsent = sanitizeConsent(new Cookies().get(META_COOKIE_NAME));
    const newConsent = {
      ...oldConsent,
      categories: {
        ...oldConsent.categories,
        [category]:
          version >= 0
            ? {
                acceptedAt: new Date(),
                acceptedVersion: version,
              }
            : {
                acceptedAt: null,
                acceptedVersion: -1,
              },
      },
    };

    // Will trigger re-render
    setCookie(META_COOKIE_NAME, newConsent, {
      domain: document.location.hostname,
      path: "/",
      expires: addMonths(new Date(), 6),
      secure: true,
      httpOnly: false,
      sameSite: "strict",
    });
  };

  const accept = (category: CookieConsentCategoryName, version: number) =>
    updateConsent(category, version);
  const reject = (category: CookieConsentCategoryName) =>
    updateConsent(category, -1);

  return { consent, accept, reject };
}

export function useCookieConsentCategory(category: CookieConsentCategoryName) {
  const { consent, accept, reject } = useCookieConsent();

  return {
    consent: consent.categories[category],
    accept: (version: number) => accept(category, version),
    reject: () => reject(category),
  };
}

const useStyles = makeStyles(() => ({
  button: {
    display: "block",
  },
}));

export function CookieConsentToggle({
  category,
  version,
}: {
  category: CookieConsentCategoryName;
  version: number;
}) {
  const classes = useStyles();
  const { consent, accept, reject } = useCookieConsentCategory(category);
  const accepted = consent.acceptedVersion === version;
  return (
    <>
      {accepted ? (
        <>
          Sie haben Ihre Zustimmung erteilt.
          <Button
            variant="outlined"
            color="primary"
            onClick={reject}
            className={classes.button}
          >
            Zustimmung widerrufen
          </Button>
        </>
      ) : (
        <>
          Sie haben Ihre Zustimmung nicht erteilt.
          <Button
            variant="outlined"
            color="primary"
            onClick={() => accept(version)}
            className={classes.button}
          >
            Zustimmung erteilen
          </Button>
        </>
      )}
    </>
  );
}
