import {
  makeStyles,
  Theme,
  AppBar,
  Button,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
  Dialog,
  Link as MuiLink,
  LinearProgress,
  ListItemIcon,
  Link,
  NoSsr,
  Divider,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { urls } from "./urls";
import { useUser } from "./user";
import { modUrl } from "./util";
import { useCookieConsentCategory } from "./cookieConsent";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import { NewsletterRegistration } from "./NewsletterRegistration";
import NextLink from "next/link";
import { Router } from "next/router";
import { useNProgress } from "@tanem/react-nprogress";
import TodayIcon from "@material-ui/icons/Today";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import EmailIcon from "@material-ui/icons/Email";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import dynamic from "next/dynamic";
import { NextMuiLink } from "./Link";
import {
  PRIVACY_LAST_UPDATE,
  PRIVACY_CURRENT_VERSION,
  PRIVACY_VERSIONS,
} from "./privacyPolicyVersions";
import clsx from "clsx";
import { MatomoOptOutDialog } from "./Matomo";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  [theme.breakpoints.down("xs")]: {
    hiddenXS: {
      display: "none",
    },
  },
  drawerIcon: {
    minWidth: 36,
  },
  alert: {
    background: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
  },
  alertToolbar: {
    padding: theme.spacing(2),
  },
}));

const TeacherAppBar = dynamic<{}>(() =>
  import("./layout/TeacherAppBar").then((mod) => mod.TeacherAppBar)
);

function IEWarning() {
  const classes = useStyles();

  // @ts-expect-error
  const isIE = window && !!window.document.documentMode;

  if (!isIE) {
    return null;
  }

  return (
    <AppBar position="static" className={classes.alert}>
      <Toolbar variant="dense" className={classes.alertToolbar}>
        <Typography variant="body1">
          Leider wird der von Ihnen verwendete Browser, Internet Explorer, auf
          dieser Webseite nicht unterstützt. Bitte benutzen Sie einen modernen
          Browser wie Chrome, Firefox, die neuste Version von Microsoft Edge,
          oder Safari.
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

export function Header() {
  const classes = useStyles();
  const user = useUser();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [newsletterRegistrationOpen, setNewsletterRegistrationOpen] =
    useState(false);

  const handleCloseDrawer = () => setDrawerOpen(false);

  const alert = process.env.NEXT_PUBLIC_ALERT_MESSAGE?.trim();

  return (
    <>
      <NewsletterPopup
        open={newsletterRegistrationOpen}
        onClose={() => setNewsletterRegistrationOpen(false)}
      />
      <NoSsr>
        <CookieBanner />
        <IEWarning />
      </NoSsr>
      {alert && (
        <AppBar position="static" className={classes.alert}>
          <Toolbar variant="dense" className={classes.alertToolbar}>
            <Typography variant="body1">{alert}</Typography>
          </Toolbar>
        </AppBar>
      )}
      <AppBar position="static" color="secondary">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Menü"
            onClick={() => setDrawerOpen(true)}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          {/*<img height="48" src="https://gitlab.com/cmfcmf/event-manager/-/raw/master/web/assets/icon/EM300.png"
            className={classes.menuButton}
            />*/}
          <NextLink href="/" passHref prefetch={false}>
            <MuiLink variant="h6" className={classes.title} color="inherit">
              Anmelde- und Eventportal
              <span className={classes.hiddenXS}> der KSL</span>
            </MuiLink>
          </NextLink>
          {user.isReady && !user.alreadySubscribedToNewsletter && (
            <Hidden smDown>
              <Tooltip title="Anmeldung zum Newsletter">
                <Button
                  color="inherit"
                  onClick={() => setNewsletterRegistrationOpen(true)}
                >
                  Newsletter
                </Button>
              </Tooltip>
            </Hidden>
          )}
          <Hidden xsDown>
            <NextLink href="/feedback" passHref>
              <Tooltip title="Feedback zu Veranstaltungen geben">
                <Button color="inherit">Feedback geben</Button>
              </Tooltip>
            </NextLink>
          </Hidden>
          {user.isReady && (
            <Hidden xsDown>
              {user.loggedIn ? (
                <NextLink href="/logout" passHref>
                  <Button color="inherit">Logout</Button>
                </NextLink>
              ) : (
                <NextLink href="/login" passHref>
                  <Tooltip
                    title={
                      <>
                        <strong>Nicht</strong> nötig ist der Login zur Anmeldung
                        für Veranstaltungen.
                      </>
                    }
                  >
                    <Button color="inherit">Login</Button>
                  </Tooltip>
                </NextLink>
              )}
            </Hidden>
          )}
          <Hidden xsDown>
            <NextLink href="/" passHref>
              <Tooltip title="Startseite">
                <IconButton edge="end" color="inherit" aria-label="Startseite">
                  <HomeIcon />
                </IconButton>
              </Tooltip>
            </NextLink>
          </Hidden>
        </Toolbar>
      </AppBar>
      {user.permissions.TEACHER && <TeacherAppBar />}
      <ProgressBar />
      <Drawer anchor="left" open={drawerOpen} onClose={handleCloseDrawer}>
        <List>
          <NextLink href="/" passHref>
            <ListItem button component="a" onClick={handleCloseDrawer}>
              <ListItemIcon className={classes.drawerIcon}>
                <TodayIcon />
              </ListItemIcon>
              <ListItemText>Aktuelle Veranstaltungen</ListItemText>
            </ListItem>
          </NextLink>
          <NextLink href="/feedback" passHref>
            <ListItem button component="a" onClick={handleCloseDrawer}>
              <ListItemIcon className={classes.drawerIcon}>
                <StarHalfIcon />
              </ListItemIcon>
              <ListItemText>Feedback geben</ListItemText>
            </ListItem>
          </NextLink>
          <NextLink href="/feedbacks" passHref>
            <ListItem button component="a" onClick={handleCloseDrawer}>
              <ListItemIcon className={classes.drawerIcon}>
                <ThumbsUpDownIcon />
              </ListItemIcon>
              <ListItemText>Feedbackarchiv</ListItemText>
            </ListItem>
          </NextLink>
          {!user.alreadySubscribedToNewsletter && (
            <ListItem
              button
              onClick={() => {
                handleCloseDrawer();
                setNewsletterRegistrationOpen(true);
              }}
            >
              <ListItemIcon className={classes.drawerIcon}>
                <EmailIcon />
              </ListItemIcon>
              <ListItemText>Newsletter</ListItemText>
            </ListItem>
          )}
          <Divider />
          {user.loggedIn ||
            (user.authorizedEmailAddresses.length > 0 && (
              <NextLink href="/registrations" passHref>
                <ListItem button component="a" onClick={handleCloseDrawer}>
                  <ListItemIcon className={classes.drawerIcon}>
                    <SupervisorAccountIcon />
                  </ListItemIcon>
                  <ListItemText>Meine Anmeldungen</ListItemText>
                </ListItem>
              </NextLink>
            ))}
          {user.loggedIn ? (
            <>
              <ListItem
                button
                component="a"
                href={modUrl("benutzer", "user", "main")}
                onClick={handleCloseDrawer}
              >
                <ListItemIcon className={classes.drawerIcon}>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText>Benutzerkonto</ListItemText>
              </ListItem>
              <NextLink href="/logout" passHref>
                <ListItem button component="a" onClick={handleCloseDrawer}>
                  <ListItemIcon className={classes.drawerIcon}>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText>Logout</ListItemText>
                </ListItem>
              </NextLink>
            </>
          ) : (
            <NextLink href="/login" passHref>
              <ListItem button component="a" onClick={handleCloseDrawer}>
                <ListItemIcon className={classes.drawerIcon}>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText>Login</ListItemText>
              </ListItem>
            </NextLink>
          )}
          <Divider />
          <NextLink href="/kontakt" passHref>
            <ListItem button component="a" onClick={handleCloseDrawer}>
              <ListItemIcon className={classes.drawerIcon}>
                <ContactSupportIcon />
              </ListItemIcon>
              <ListItemText>Kontakt / Hilfe</ListItemText>
            </ListItem>
          </NextLink>
          <ListItem
            button
            component="a"
            href={urls.imprint}
            onClick={handleCloseDrawer}
          >
            <ListItemIcon className={classes.drawerIcon} />
            <ListItemText>Impressum</ListItemText>
          </ListItem>
          <NextLink href="/datenschutz" passHref>
            <ListItem button component="a" onClick={handleCloseDrawer}>
              <ListItemIcon className={classes.drawerIcon} />
              <ListItemText>Datenschutz</ListItemText>
            </ListItem>
          </NextLink>
        </List>
      </Drawer>
    </>
  );
}

function NewsletterPopup({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog onClose={onClose} open={open} fullScreen={fullScreen}>
      <NewsletterRegistration dialog dialogOnClose={onClose} />
    </Dialog>
  );
}

function ProgressBar() {
  const [hasNotRun, setHasNotRun] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingKey, setLoadingKey] = useState(0.0);
  const [showProgress, setShowProgress] = useState(false);
  const { progress, isFinished, animationDuration } = useNProgress({
    isAnimating: showProgress,
  });

  useEffect(() => {
    const start = () => setLoading(true);
    const end = () => setLoading(false);

    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeError", end);

    return () => {
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    };
  }, []);

  useEffect(() => {
    if (loading) {
      const id = setTimeout(() => {
        setShowProgress(true);
        setLoadingKey(Math.random());
        setHasNotRun(false);
      }, 800);
      return () => clearTimeout(id);
    } else {
      setShowProgress(false);
    }
  }, [loading]);

  const isReallyFinished = isFinished || hasNotRun;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        opacity: isReallyFinished ? 0 : 1,
        transition: `opacity ${animationDuration}ms linear`,
      }}
    >
      <LinearProgress
        variant="determinate"
        value={progress * 100}
        color="primary"
        key={loadingKey}
      />
    </div>
  );
}

const useCookieBannerStyles = makeStyles<Theme, { doNotTrack: boolean }>(
  (theme) => ({
    toolbar: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
      flexDirection: ({ doNotTrack }) => (doNotTrack ? undefined : "column"),
    },
    message: {
      flexGrow: 1,
    },
    singleButton: {
      flexShrink: 0,
    },
    button: {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        marginTop: theme.spacing(1),
      },
      marginTop: ({ doNotTrack }) =>
        doNotTrack ? undefined : theme.spacing(1),
    },
    buttonWrapper: {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
      [theme.breakpoints.up("sm")]: {
        "& > *": {
          marginLeft: theme.spacing(1),
        },
        "& > :first-child": {
          marginLeft: 0,
        },
      },
    },
  })
);

function CookieBanner() {
  // const doNotTrack = isDnt() === true;
  const classes = useCookieBannerStyles({ doNotTrack: true /* doNotTrack */ });
  const { consent: privacyPolicyConsent, accept: acceptPrivacyPolicy } =
    useCookieConsentCategory("privacyPolicy");
  // const {
  //   consent: matomoConsent,
  //   accept: acceptMatomo,
  //   reject: rejectMatomo,
  // } = useCookieConsentCategory("matomo");

  const [matomoDialogOpen, setMatomoDialogOpen] = useState(false);

  if (
    privacyPolicyConsent.acceptedVersion === PRIVACY_CURRENT_VERSION /* &&
    (doNotTrack ||
      [0, PRIVACY_CURRENT_VERSION].includes(matomoConsent.acceptedVersion)) */
  ) {
    return null;
  }

  const lastAcceptedPrivacyPolicyDate = (
    PRIVACY_VERSIONS as Record<number, string>
  )[privacyPolicyConsent.acceptedVersion];

  return (
    <AppBar position="static" color="secondary" elevation={0}>
      <Toolbar variant="dense" className={classes.toolbar}>
        <div>
          <Typography
            className={classes.message}
            variant="body2"
            color="inherit"
          >
            {privacyPolicyConsent.acceptedVersion !== PRIVACY_CURRENT_VERSION &&
              lastAcceptedPrivacyPolicyDate && (
                <>
                  <strong>
                    Die Datenschutzbestimmungen haben sich geändert
                    {lastAcceptedPrivacyPolicyDate &&
                      `, seit Sie die Version vom ${lastAcceptedPrivacyPolicyDate} akzeptiert haben`}
                    .
                  </strong>
                  <br />
                </>
              )}
            Diese Webseite verwendet{" "}
            <Link href="https://de.wikipedia.org/wiki/HTTP-Cookie">
              Cookies
            </Link>
            . Wir benutzen Cookies unter anderem für einige für den Betrieb der
            Seite notwendige Funktionen wie Login und Benachrichtigungen. Zudem
            benutzen wir das Open-Source-Software-Tool{" "}
            <Link href="https://matomo.org/">Matomo</Link> zur Analyse des
            Surfverhaltens unserer Nutzer, wofür ebenfalls Cookies benutzt
            werden. Die Daten werden dabei nur auf unserem Server in Deutschland
            gespeichert und verarbeitet und nicht an Dritte weitergegeben.{" "}
            <MuiLink href="#" onClick={() => setMatomoDialogOpen(true)}>
              Hier klicken
            </MuiLink>
            , um das Matomo-Tracking zu konfigurieren.
            <MatomoOptOutDialog
              open={matomoDialogOpen}
              onClose={() => setMatomoDialogOpen(false)}
            />{" "}
            Mehr Informationen zu unserer Nutzung von Cookies und der
            Verarbeitung von personenbezogenen Daten gibt es in den{" "}
            <NextMuiLink href="/datenschutz#content-top">
              Datenschutzbestimmungen
            </NextMuiLink>{" "}
            (Stand: {PRIVACY_LAST_UPDATE}). Mit der Nutzung der Webseite
            erklären Sie sich mit der Datenverarbeitung gemäß
            Datenschutzbestimmungen einverstanden.
          </Typography>
          {/*!doNotTrack && (<MatomoIframe />)*/}
        </div>
        <Button
          variant="outlined"
          color="inherit"
          className={clsx(classes.button, classes.singleButton)}
          onClick={() => {
            acceptPrivacyPolicy(PRIVACY_CURRENT_VERSION);
          }}
        >
          Hinweis verbergen
        </Button>

        {/* doNotTrack ? (
          <Button
            variant="outlined"
            color="inherit"
            className={clsx(classes.button, classes.singleButton)}
            onClick={() => {
              acceptPrivacyPolicy(PRIVACY_CURRENT_VERSION);
              rejectMatomo();
            }}
          >
            Hinweis verbergen
          </Button>
        ) : (
            <div className={classes.buttonWrapper}>
              <Button
                variant="outlined"
                color="inherit"
                className={classes.button}
                onClick={() => {
                  acceptPrivacyPolicy(PRIVACY_CURRENT_VERSION);
                  acceptMatomo(PRIVACY_CURRENT_VERSION);
                }}
              >
                Zustimmen & alle Cookies erlauben
              </Button>
              <Button
                variant="outlined"
                color="inherit"
                className={classes.button}
                onClick={() => {
                  acceptPrivacyPolicy(PRIVACY_CURRENT_VERSION);
                  acceptMatomo(0); // = read, but rejected
                }}
              >
                Zustimmen & nur notwendige Cookies erlauben
              </Button>
            </div>
        ) */}
      </Toolbar>
    </AppBar>
  );
}
