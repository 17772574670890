import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  CardActions,
  DialogTitle,
  DialogContent,
  DialogActions,
  Hidden,
} from "@material-ui/core";
import React, { useRef } from "react";
import { useCSRF } from "./user";
import { modUrl } from "./util";
import { PrivacyCheckbox } from "./form";
import { NextMuiLink } from "./Link";

export function NewsletterRegistration({
  dialog,
  dialogOnClose,
}: {
  dialog?: boolean;
  dialogOnClose?: () => void;
}) {
  if (dialog && dialogOnClose) {
    return (
      <>
        <DialogTitle>Newsletter Anmeldung</DialogTitle>
        <FormWrapper
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <DialogContent>
            <FormFields />
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              name="submit"
              variant="contained"
              color="primary"
            >
              <Hidden xsDown>Zum Newsletter</Hidden> anmelden
            </Button>
            <Button type="button" onClick={dialogOnClose}>
              schließen
            </Button>
          </DialogActions>
        </FormWrapper>
      </>
    );
  }

  return (
    <FormWrapper>
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Newsletter Anmeldung
          </Typography>
          <FormFields />
        </CardContent>
        <CardActions>
          <Button
            type="submit"
            name="submit"
            variant="contained"
            color="primary"
          >
            Zum Newsletter anmelden
          </Button>
        </CardActions>
      </Card>
    </FormWrapper>
  );
}

function FormWrapper(
  props: React.PropsWithChildren<{ style?: React.CSSProperties }>
) {
  const csrf = useCSRF();
  return (
    <form
      style={props.style}
      method="post"
      action={modUrl("Newsletter", "user", "edit", { ot: "user" })}
      encType="application/x-www-form-urlencoded"
    >
      <input type="hidden" name="authid" value={csrf} />
      <input type="hidden" name="user[frequency]" value="0" />
      <input type="hidden" name="user[type]" value="0" />
      <input type="hidden" name="user[lang]" value="de" />
      {props.children}
    </form>
  );
}
function FormFields() {
  return (
    <>
      <Typography gutterBottom>
        In unserem Newsletter informieren wir wöchentlich über aktuelle
        Veranstaltungen und Angebote. Der letzte Newsletter kann{" "}
        <NextMuiLink href="/newsletter/aktuell" target="_blank">
          hier
        </NextMuiLink>{" "}
        eingesehen werden.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            size="small"
            required
            fullWidth
            margin="normal"
            label="Name"
            name="user[name]"
            type="text"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            size="small"
            required
            fullWidth
            margin="normal"
            label="E-Mail-Adresse"
            name="user[email]"
            type="email"
          />
        </Grid>
      </Grid>
      <PrivacyCheckbox name="user[tos]" value="1" />
    </>
  );
}
