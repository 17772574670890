import {
  FormControlLabel,
  Checkbox,
  CheckboxProps,
  Link as MuiLink,
  FormHelperText,
  FormControl,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { PRIVACY_LAST_UPDATE } from "./privacyPolicyVersions";

const useStyles = makeStyles(() => ({
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

export function PrivacyCheckbox(
  props: CheckboxProps & { error?: string | null }
) {
  const { error, ...checkboxProps } = props;
  const classes = useStyles();

  return (
    <FormControl margin="normal" fullWidth>
      <FormControlLabel
        control={
          <Checkbox {...checkboxProps} required className={classes.checkbox} />
        }
        label={
          <>
            Ich stimme den{" "}
            <MuiLink href="/datenschutz" target="blank">
              Datenschutzbestimmungen
            </MuiLink>{" "}
            (Stand: {PRIVACY_LAST_UPDATE}) zu.
          </>
        }
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
}
