import { emUrl, modUrl } from "./util";

export const urls = {
  register: modUrl("benutzer", "user", "register"),
  resetPassword: modUrl("benutzer", "user", "lostpassword"),

  myAccount: emUrl("user", "dashboard"),
  myEvents: emUrl("admin", "viewEvents", { ownEventsOnly: 1 }),

  contactSchool: "https://ksliebfrauen.de/service/kontakt-anfahrt",
  imprint: "https://ksliebfrauen.de/impressum",
};
