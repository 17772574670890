import React from "react";
import {
  Box,
  Container,
  Button,
  Grid,
  ButtonProps,
  makeStyles,
  Typography,
  colors,
  Theme,
} from "@material-ui/core";
import { useUser } from "./user";
import { urls } from "./urls";
import MuiLink from "@material-ui/core/Link";
import NextLink from "next/link";
import { NewsletterRegistration } from "./NewsletterRegistration";
import { BOTTOM_NAV_HEIGHT, useBottomNavigation } from "./MyBottomNavigation";
import { orange } from "@material-ui/core/colors";

const useStyles = makeStyles<Theme, { bottomNavigationVisible: boolean }>(
  (theme) => ({
    button: {
      marginBottom: theme.spacing(1),
      backgroundColor: colors.grey[50],
      color: theme.palette.getContrastText(colors.grey[50]),
    },
    mb: {
      marginBottom: theme.spacing(1),
    },
    footer: {
      marginTop: theme.spacing(4),
      backgroundColor: orange["100"],
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginBottom: ({ bottomNavigationVisible }) =>
        bottomNavigationVisible ? BOTTOM_NAV_HEIGHT : 0,
    },
  })
);

export function Footer() {
  const { loggedIn, alreadySubscribedToNewsletter } = useUser();
  const bottomNavigationVisible = useBottomNavigation();
  const classes = useStyles({ bottomNavigationVisible });

  return (
    <div className={classes.footer}>
      <Container>
        <Grid container spacing={2} style={{ marginTop: 0, marginBottom: 0 }}>
          <Grid item xs={12} sm={4} md={3} container direction="column">
            {loggedIn ? (
              <FooterButton href="/logout" nextLink>
                Logout
              </FooterButton>
            ) : (
              <>
                <FooterButton href="/login" nextLink>
                  Login
                </FooterButton>
                <FooterButton href={urls.register}>
                  Konto erstellen
                </FooterButton>
              </>
            )}
            <FooterButton href="/datenschutz" nextLink>
              Datenschutz
            </FooterButton>
            <FooterButton href="/kontakt" nextLink>
              Kontakt / Hilfe
            </FooterButton>
            <FooterButton href={urls.imprint}>Impressum</FooterButton>
          </Grid>
          <Grid item xs={12} sm={8} md={9}>
            {!alreadySubscribedToNewsletter && <NewsletterRegistration />}
          </Grid>
        </Grid>
        <Box paddingY={2}>
          <Typography variant="body2">
            &copy; {new Date().getFullYear()} Christian Flach,{" "}
            <MuiLink href="https://ksliebfrauen.de">
              katholische Schule Liebfrauen
            </MuiLink>
          </Typography>
        </Box>
      </Container>
    </div>
  );
}

const useFooterButtonStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(1),
    backgroundColor: colors.grey[50],
    color: theme.palette.getContrastText(colors.grey[50]),
  },
}));

function FooterButton({
  children,
  nextLink,
  ...props
}: ButtonProps & { nextLink?: boolean; href: string }) {
  const classes = useFooterButtonStyles();
  const btn = (
    <Button
      className={classes.button}
      variant="contained"
      color="default"
      size="small"
      {...props}
    >
      {children}
    </Button>
  );

  if (nextLink === true) {
    return <NextLink href={props.href}>{btn}</NextLink>;
  }

  return btn;
}
