import React from "react";
import Head from "next/head";
import { AppProps } from "next/app";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "../src/theme";
import { ExternalLinkProvider } from "../src/ExternalLink";
import { Footer } from "../src/Footer";
import { Header } from "../src/Header";
import { Box } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
import { GlobalDataProvider } from "../src/user";
import { SWRConfig } from "swr";
import { swrFetcher, rawUrl } from "../src/util";
import { CookiesProvider } from "react-cookie";
import { MyErrorBoundary } from "../src/MyErrorBoundary";
import { BottomNavigationProvider } from "../src/MyBottomNavigation";
import { MatomoTracker } from "../src/Matomo";

import "../src/fonts/fonts.css";
import "../src/Event/imageGallery.css";

const swrConfig = {
  fetcher: swrFetcher,
};

export const AP_DESCRIPTION = `Das Anmelde- und Eventportal der katholischen Schule Liebfrauen dient zur Information und Anmeldung von Schüler:innen und Ehemaligen zu schulischen und außerschulischen Veranstaltungen.`;

export default function MyApp(props: AppProps) {
  const { Component, pageProps } = props;

  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement!.removeChild(jssStyles);
    }
  }, []);

  return (
    <React.Fragment>
      <Head>
        <title>Anmelde- und Eventportal der KSL</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link
          rel="icon"
          href={rawUrl("/themes/EventManager/images/favicon.ico")}
          type="image/x-icon"
        />
        <meta name="description" content={AP_DESCRIPTION} />
        <meta name="git-commit" content={process.env.NEXT_PUBLIC_COMMIT_HASH} />
      </Head>
      <MyErrorBoundary>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider maxSnack={3}>
            <SWRConfig value={swrConfig}>
              <CookiesProvider>
                <GlobalDataProvider>
                  <BottomNavigationProvider>
                    <MatomoTracker />
                    <MyErrorBoundary>
                      <Header />
                    </MyErrorBoundary>
                    <ExternalLinkProvider>
                      <Box m={2}>
                        <MyErrorBoundary>
                          <Component {...pageProps} />
                        </MyErrorBoundary>
                      </Box>
                    </ExternalLinkProvider>
                    <MyErrorBoundary>
                      <Footer />
                    </MyErrorBoundary>
                  </BottomNavigationProvider>
                </GlobalDataProvider>
              </CookiesProvider>
            </SWRConfig>
          </SnackbarProvider>
        </ThemeProvider>
      </MyErrorBoundary>
    </React.Fragment>
  );
}
