import { blueGrey } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";
import { deDE } from "@material-ui/core/locale";

const PRIMARY_COLOR = "#512da8";
const SECONDARY_COLOR = "#ffc107";

export const ADMIN_COLOR_MAIN = blueGrey[500];
export const ADMIN_COLOR_MAIN_HOVER = blueGrey[700];
export const ADMIN_COLOR_MAIN_CONTRAST = "#fff";

export const ADMIN_COLOR_DARK = blueGrey[800];
export const ADMIN_COLOR_DARK_CONTRAST = "#fff";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: PRIMARY_COLOR,
      },
      secondary: {
        main: SECONDARY_COLOR,
      },
    },
    overrides: {
      MuiBottomNavigation: {
        root: {
          backgroundColor: SECONDARY_COLOR,
        },
      },
      MuiBottomNavigationAction: {
        label: {
          // IMHO likely a bug in Material-UI
          textAlign: "center",
        },
      },
      MuiTab: {
        root: {
          // [theme.breakpoints.up('sm')]: {
          "@media (min-width:600px)": {
            padding: "6px 16px",
            minWidth: 0,
          },
        },
      },
    },
    typography: {
      h4: {
        fontFamily: "'Roboto Slab'",
      },
    },
  },
  deDE
);

export default theme;
