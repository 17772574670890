import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { ErrorInfo } from "react";

type Props = React.PropsWithChildren<{}>;
type State = { hasError: boolean };

export function logError(data: any) {
  try {
    console.error(data);
    fetch("/api/client-error", {
      method: "POST",
      body: JSON.stringify(data, (key, value) => {
        // https://stackoverflow.com/a/18391400/2560557
        if (value instanceof Error) {
          var error = {};

          Object.getOwnPropertyNames(value).forEach((prop) => {
            // @ts-expect-error
            error[prop] = value[prop];
          });

          return error;
        }

        return value;
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Requested-By": "EM",
      },
    });
  } catch {}
}

export class MyErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box py={4}>
          <Alert severity="error">
            Leider ist etwas schiefgegangen. Unter Umständen hilft es, die Seite
            neu zu laden.
          </Alert>
        </Box>
      );
    }
    return this.props.children;
  }
}
